import { EditGuesser, ListGuesser } from "react-admin";

import create from './create';
import list from './list';
import edit from './edit';

export default {
	create,
	edit,
	list
};