//import logo from './logo.svg';
import './App.css';

import { Admin, Resource, CustomRoutes, WithPermissions, usePermissions } from 'react-admin';
//import { Route } from 'react-router-dom';

import dataProvider from './Providers/dataProvider';
import authProvider from './Providers/authProvider';

//import { Dashboard, Settings } from './Pages';
//import { AppLayout } from './Layout';
import Event from './Resources/Event';
import Expert from './Resources/Expert';
import User from './Resources/User';
import FormEntry from './Resources/FormEntry';
import Voiceover from './Resources/Voiceover';
import Interview from './Resources/Interview';

import PersonIcon from '@mui/icons-material/Person'; // Users
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'; // Participant
import AssignmentIcon from '@mui/icons-material/Assignment'; // PreExperience
import GroupIcon from '@mui/icons-material/Group'; // Personas
import EventIcon from '@mui/icons-material/Event';


function App() {
	
	return (
		<Admin
			dataProvider={dataProvider}
			authProvider={authProvider}
			disableTelemetry
			requireAuth
			>
			<Resource name="events" {...Event} icon={EventIcon} />
			<Resource name="experts" {...Expert} />
			<Resource name="users" {...User} icon={PersonIcon} />
			<Resource name="form-entries" options={{label: 'Form Entries' }} {...FormEntry} icon={AssignmentIndIcon} />
			<Resource name="voiceovers" options={{label: 'Pre-Experience' }} {...Voiceover} icon={AssignmentIcon} />
			<Resource name="interviews" {...Interview} />
			{ /*
			<Resource name="settings" list={SettingList} edit={SettingEdit} create={SettingCreate} />
			*/}
		</Admin>
	);
}

export default App;
