import React from 'react';
import { Edit, SimpleForm, TextInput, FileInput, required } from 'react-admin';
import { AudioField } from '../../components/AudioField';

const RichTextInput = React.lazy(() =>
	import('ra-input-rich-text').then( module => ({
		default: module.RichTextInput
	}))
);

const edit = (props) => (

	<Edit {...props}>
		<SimpleForm>
			<TextInput source="name" validate={required()} />
			<RichTextInput source="content" fullWidth />
			<FileInput source="audio" accept={{ 'audio/*': [ '.mp3' ] }}>
				<AudioField source="src" title="title"  />
			</FileInput>
		</SimpleForm>
	</Edit>
);

export default edit;
