import {
	Datagrid,
	DateField,
	List,
	FunctionField,
	TextField,
	EmailField,
	TopToolbar,
	CreateButton

} from 'react-admin';

/*
	@todo: Reindex controls.
*/

const ListActions = () => (
	<TopToolbar>
		<CreateButton />
	</TopToolbar>
);

export const list = () => (
	<List actions=<ListActions />>
		<Datagrid rowclick="edit">
			<TextField source="id" />
			<FunctionField source="last_name" label="Name" render={ record => `${record.first_name} ${record.last_name}` } />
			<EmailField source="email" />
			<TextField source="title" />
			<TextField source="company" />
			<DateField source="updatedAt" label="Last Modified" showDate showTime />
		</Datagrid>
	</List>
);

export default list;