import React from 'react';
import { Edit, SimpleForm, TextInput, ImageInput, ImageField, ArrayInput, SimpleFormIterator, required } from 'react-admin';

const edit = (props) => (

	<Edit {...props}>
		<SimpleForm>
			<ImageInput source="image" label="Photo">
				<ImageField source="src" />
			</ImageInput>
			<TextInput source="first_name" validate={required()} />
			<TextInput source="last_name" validate={required()} />

			<TextInput source="company" />
			<TextInput source="title" />
			<TextInput source="group" />
			<TextInput source="industry_focus" multiline rows={5} />
			<TextInput source="expertise" multiline rows={5} />
			<TextInput source="bio" multiline rows={5} />
			<TextInput source="location" />
			<TextInput source="email" type="email" />
			<TextInput source="phone" />

			<ArrayInput source="links">
				<SimpleFormIterator inline>
					<TextInput source="title" />
					<TextInput source="url" />
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	</Edit>
);

export default edit;
