export const StatusChoices = [
	{ id: 'active', name: 'Active' },
	{ id: 'inactive', name: 'Inactive' }
];

export const DefaultValues = {
	"status": "active",
	"persona_id": 1
};

export const voiceoverOptionText = r => {
	const { name, content } = r;
	let label = name;

	if ( content ) {
		const contentFrag = content.length > 30 ? content.substring( 0, 30 ) + '...' : content;
		label += ' - ' + contentFrag;
	}
	
	return label;
};

export const expertOptionText = (r) => `${r.first_name} ${r.last_name} - ${r.company}`;
