import jsonServerProvider from 'ra-data-json-server';
import { withLifecycleCallbacks } from 'react-admin';

function handleUploadedFile( targetField ) {
	return async ( params ) => {
		const field = params.data[targetField];

		if ( field.rawFile instanceof File ) {
			field.type = field.rawFile.type;
			field.rawFile = await convertFileToBase64( field.rawFile );
		}

		return params;
	};
}

function convertFileToBase64( file ) {
	return new Promise( (resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			const result = reader.result.split(',')[1];
			resolve( result );
		};

		reader.onerror = reject;
		reader.readAsDataURL( file );
	});
}

export const dataProvider = withLifecycleCallbacks( jsonServerProvider('/api'), [
	{
		resource: 'voiceovers',
		beforeCreate: handleUploadedFile( 'audio' ),
		beforeUpdate: handleUploadedFile( 'audio' )
	},

	{
		resource: 'experts',
		beforeCreate: handleUploadedFile( 'image' ),
		beforeUpdate: handleUploadedFile( 'image' )
	}

]);

export default dataProvider;