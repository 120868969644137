import React from 'react';
import { List, Datagrid, TextField, DateField, EditButton, DeleteButton, TextInput, FilterButton, CreateButton, TopToolbar, ReferenceField, ReferenceInput, AutocompleteInput, SearchInput } from 'react-admin';

const ListActions = () => (
	<TopToolbar>
		<CreateButton />
	</TopToolbar>
);


const list = () => (
	<List
		actions={<ListActions />}
		>
		<Datagrid rowClick="edit">
			<TextField source="name" />
			<EditButton />
		</Datagrid>
	</List>
);

export default list;
