import {
	Datagrid,
	DateField,
	List,
	TextField,
	TopToolbar,
	CreateButton

} from 'react-admin';

const ListActions = () => (
	<TopToolbar>
		<CreateButton />
	</TopToolbar>
);
  
export const list = (props) => (
	<List {...props} actions=<ListActions />>
		<Datagrid rowClick="edit">
			<TextField source="name" />
			<DateField source="start_date" />
			<TextField source="status" />
		</Datagrid>
	</List>
);

export default list;