//import { ShowGuesser as show } from "react-admin";
import { DateField, ReferenceField, Show, SimpleShowLayout, TextField, RichTextField } from 'react-admin';

export const show = () => (
    <Show>
        <SimpleShowLayout>
            <ReferenceField source="event_id" reference="events" />
            <ReferenceField source="registration_id" reference="form-entries" />

            <DateField source="createdAt" showDate showTime />
            <DateField source="updatedAt" showDate showTime />
            <RichTextField source="transcript_formatted" label="Transcript" />
        </SimpleShowLayout>
    </Show>
);

export default {
	show
};